
// Declaración de variables
const linkClientes = 'https://my360131.sapbydesign.com/sap/byd/odata/ana_businessanalytics_analytics.svc/RPZCEE5445BDB4F5D386CCD3CQueryResults?$select=TBP_UUID,CBP_UUID,TCASH_DSCNT_TERM,CTAX_ID_NR';
const  Usuario = 'SU5URUdSQUNJT05FU0NPTlNVTU9TOjkwMjdSZWdhbmFt'
const linkconsumo = 'https://sap.citalsa.com:9037/api/ServiceConnecToServer/Sap'
const linkProyectos = 'https://my360131.sapbydesign.com/sap/byd/odata/ana_businessanalytics_analytics.svc/RPPROPRJU05_Q0007QueryResults?$select=T1GM3WU5JA16A30Q9G3BCCHDNUX,CPROJECT_UUID,TPR_STATUS_LFC,TPR_BUY_PTY_UUID,TPR_RESP_CC_UUID,C1TMCZAZDOGVKU7KSI2XJUK4LC5,CPR_BUY_PTY_UUID,TPROJECT_UUID&$top=99999&$format=json&$orderby=CPROJECT_UUID'
// Exportación de las variables
export { 
    linkClientes ,
    Usuario,
    linkconsumo,
    linkProyectos
};