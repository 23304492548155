import * as React from 'react';
import { Container } from '@mui/system';
import Busqueda from '../Componentes/Busqueda'
import { styled } from '@mui/material/styles';
import  '../index.css'
import { Grid, Paper, Box} from '@mui/material';
const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    fontSize: "20px",
    boxShadow:"none",
    color: theme.palette.text.secondary
}));


export default function Proyectos() {
    const [Cambiador, setCambiador] = React.useState("");
    React.useEffect(() => {
    }, [Cambiador])
    const Datos = () => {
        setCambiador(Cambiador + 1);
    }
    return (
        <div>
            <div style={{ borderBottom: "0.2em solid #c82339" }} >
                <Container sx={{ bgcolor: "white", textAlign: "end", }} >
                    <Box sx={{ flexGrow: 20 }}>
                        <Grid container >
                            <Grid item xs={9}>
                                <Item><p style={{ color: "#c82339", fontSize: "15px", fontFamily: "Roboto" }}>
                            Bienvenido  {localStorage.Cliente}  estos son los proyectos que tienes con nosotros</p> </Item>
                            </Grid>
                            <Grid item xs={3}>
                                <Item><img style={{  }} 
                                src='https://cdn.shopify.com/s/files/1/0538/6354/7062/files/logo_CI_Talsa_140x.png?v=1614001915' id="logoproyectos"></img> </Item>
                            </Grid>
                        </Grid>
                    </Box>
                </Container>
            </div>
            <Busqueda Datos={Datos}></Busqueda>
        </div>
    );
}

