import logo from './logo.svg';
import './App.css';
import { Container } from '@mui/system';
import Proyectos from '../src/Componentes/Proyectos'
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link
} from 'react-router-dom';

function App() {
  return (
    <Router>
      <div style={{}}>
        <link to="/consultadeproyectos"> 
       
        </link>
      </div>
      <Routes>
        <Route exact path='/consultadeproyectos'  element={<Proyectos></Proyectos>} ></Route>
      </Routes>
      <Routes>
        <Route exact path=''  element={<Proyectos></Proyectos>} ></Route>
      </Routes>
    </Router>

  );
}

export default App;
