import React, { Component } from 'react';
import { Container } from '@mui/system';
import Typography from '@mui/material/Typography';
import GetProyectos from '../Componentes/GetProyectos'

export default function Busqueda({ Datos }) {


    return (
        <Container sx={{ bgcolor: "" }} >
            <Container>
                <Typography style={{
                    fontFamily: "Roboto", color: "#c82339"
                    , fontSize: "45px", padding: "35px"
                }} variant="h1"
                    gutterBottom>CONSULTA EL ESTADO DE TUS SOLICITUDES DE SERVICIO TÉCNICO
                </Typography>
            </Container>
            <Container>
                <Typography style={{
                    fontFamily: "Roboto", color: "#c82339"
                    , fontSize: "15px", padding: "5px 50px 10px 50px"
                }} variant="h1"
                    gutterBottom>Ingresa tu número de cédula o NIT incluyendo el dígito de verificación
                </Typography>
            </Container>
            <Container>
                <GetProyectos Datos={Datos}></GetProyectos>
            </Container>
        </Container>
    );
}

