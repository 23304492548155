import * as React from 'react';
import { Container } from '@mui/system';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TablePagination from '@mui/material/TablePagination';
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import SearchOffIcon from '@mui/icons-material/SearchOff';
import LinearProgress from '@mui/material/LinearProgress';
import Box from "@mui/material/Box";
import { Select, MenuItem } from '@mui/material';
import swal from 'sweetalert';
import { linkClientes, Usuario , linkconsumo, linkProyectos} from './variables';



export default function GetProyectos({ Datos }) {

    const [page, setPage] = React.useState(0);
    const [contar, setContar] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [nit, setNit] = React.useState("");
    let [vector, setVector] = React.useState([]);
    let [carga, setCarga] = React.useState(1);
    const [age, setAge] = React.useState("Liberado");


    async function ConsultaClienteSap(nit) {
        setCarga(2);
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({
            "Usuario": Usuario,
            "Liga": `${linkClientes}&$filter=(CTAX_ID_NR eq '${nit}' and CSTATUS eq '2')&$top=1&$format=json`
        });

        const requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        await fetch(linkconsumo, requestOptions)
            .then(response => response.text())
            .then(result => {
                
                console.log(JSON.parse(result).d.results[0])
                localStorage.Cliente = JSON.parse(result).d.results[0].TBP_UUID;
                Datos();
                CargarProyectoSAP(JSON.parse(result).d.results[0].CBP_UUID)
            })
            .catch(error => {
                setCarga(1);
                console.log('error', error)
            });
    }

    const handleChangePage = (event, newPage) => {
        setPage(parseInt(newPage));

    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(event.target.value);
    };

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: "#c82339",
            color: theme.palette.common.white,
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
        },
    }));

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
        // hide last border
        '&:last-child td, &:last-child th': {
            border: 0,
        },
    }));
    async function CargarProyectoSAP(dato) {

        setVector([])
        let VectorProyec = [];
        if (dato === "") {
            return [["", "", "", "", ""]];
        }
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        let liga = `${linkProyectos}&$filter=CPR_BUY_PTY_UUID eq '${dato}' and CPR_STATUS_LFC eq '${age === "Liberado" ? 2 : 4}' `;
        const raw = JSON.stringify({
            "Usuario": Usuario,
            "Liga": liga
        });
        const requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };
        await fetch(linkconsumo, requestOptions)
            .then(response => response.text())
            .then(result => {
                if (JSON.parse(result).d.results.length === 0) {
                    setCarga(1);
                    swal("No se encontro registros para este cliente!", "Valide la información!", "warning");
                } else {

                    JSON.parse(result).d.results.forEach(element => {
                        vector = VectorProyec;
                        vector.push({
                            Proyecto: element.CPROJECT_UUID, Cliente: element.TPR_BUY_PTY_UUID,
                            Estado: element.TPR_STATUS_LFC, Sede: element.TPR_RESP_CC_UUID, Comentario: element.C1TMCZAZDOGVKU7KSI2XJUK4LC5,
                            Avance: element.T1GM3WU5JA16A30Q9G3BCCHDNUX,
                            TextProyecto: element.TPROJECT_UUID
                        })
                        setCarga(1);
                    });
                    setVector(vector)
                    setContar(contar + 1);
                }
            })
            .catch(error => console.log('error', error));
        return VectorProyec;
    }
    React.useEffect(() => {
    }, [contar])

    return (
        <Container>
            <Container styled={{ padding: "15px", backgroundColor: "red" }}>
                <Paper component="form"
                    sx={{
                        p: '2px 4px', display: 'flex', alignItems: 'center',
                        borderBottom: "0.2em solid #c82339"
                    }}>
                    <InputBase value={nit} onInputCapture={(event, newInputValue) => {
                        setNit(event.target.value)
                    }}
                        sx={{ ml: 1, flex: 1 }}
                        placeholder="Nit del cliente"
                        inputProps={{ 'aria-label': 'Nit del cliente' }} />
                 

                    <Select
                        labelId="demo-simple-select-autowidth-label"
                        id="demo-simple-select-autowidth"
                        value={age}
                        onChange={(e) => {
                            setAge(e.target.value);
                        }}
                        autoWidth
                        label="Sucursal">
                        <MenuItem value={"Liberado"}>Liberado</MenuItem>
                        <MenuItem value={"Cerrado"}>Cerrado</MenuItem>
                    </Select>

                    <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                    <IconButton color="primary" sx={{ p: '10px' }} aria-label="directions" onClick={() => {
                        ConsultaClienteSap(nit)
                    }}>
                        <PersonSearchIcon />
                    </IconButton>

                </Paper>
            </Container>
            <br />
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell># Proyecto</StyledTableCell>
                            <StyledTableCell>Proyecto</StyledTableCell>
                            <StyledTableCell align="left">Estado</StyledTableCell>
                            <StyledTableCell align="left">Sede</StyledTableCell>
                            <StyledTableCell align="left">Avance</StyledTableCell>
                            <StyledTableCell align="left">Comentario</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {carga === 2 ?
                            <Box sx={{ width: '510%' }}>
                                <LinearProgress />
                            </Box> : vector.slice(rowsPerPage * (page + 1) - rowsPerPage, rowsPerPage > 10 ? rowsPerPage : rowsPerPage * page + 10).map((row) => (
                                <StyledTableRow key={row.Proyecto}>
                                    <StyledTableCell component="th" scope="row">{row.Proyecto}</StyledTableCell>
                                    <StyledTableCell align="left">{row.TextProyecto}</StyledTableCell>
                                    <StyledTableCell align="left">{row.Estado}</StyledTableCell>
                                    <StyledTableCell align="left">{row.Sede}</StyledTableCell>
                                    <StyledTableCell align="left">{row.Avance}</StyledTableCell>
                                    <StyledTableCell align="left">{row.Comentario}</StyledTableCell>
                                </StyledTableRow>
                            ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 20, 30, 40, 100, vector.length]}
                component="div"
                count={vector.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage} />
        </Container>
    );
}
